export default {
    AvailabilityCheckFail:
        'The availability check for the proposal could not be completed due to a problem with the request. The proposal cannot be {{type}}.',
    static: 'static',
    digital: 'digital',
    MsgNoMatchingRecords: 'No matching {{resource}} found.',
    ResourceProposals: 'proposals',
    ResourceProposal: 'proposal',
    ResourcePackages: 'packages',
    ResourcePackage: 'package',
    ResourceRateCards: 'rate cards',
    ResourceFace: 'face',
    ResourceScreen: 'screen',
    ResourceScreens: 'screens',
    ResourceSigns: 'signs',
    ResourceFaces: 'faces',
    DaySingular: 'day',
    DayPlural: 'days',
    HourSingular: 'hour',
    HourPlural: 'hours',
    MinuteSingular: 'minute',
    MinutePlural: 'minutes',
    LblTimeUnitDiff: '{{value}} {{unit}}',
    ResourceProposalItems: 'line items',
    ResourceProposalItem: 'line item',
    BtnBuildNewProposal: 'Build new proposal',
    BtnBuildNewPackage: 'Build new package',
    BtnCartClearAll: 'Clear all',
    BtnCartSendTo: 'Send to',
    BtnClearAll: 'Clear all',
    BtnClearSelection: 'Clear Selection',
    BtnSendTo: 'Send to ...',
    TabListView: 'List view',
    TabMapView: 'Map view',
    BtnLightboxAddToProposal: 'Add to Proposal',
    BtnLightboxUpdateProposal: 'Update Proposal Item',
    BtnLightboxAmendProposal: 'Amend Proposal Item',
    BtnLightboxCheckAvailability: 'Check availability',
    BtnLightboxCheckingAvailability: 'Checking availability',
    BtnLightboxChooseFilters: 'Update Filters',
    BtnLightboxChooseScreens: 'Update Screens',
    BtnLightboxUpdateSelection: 'Update Selection',
    BtnLightboxUpdateSubmitting: 'Updating',
    BtnLightboxAmendSubmitting: 'Amending',
    BtnImportCSV: 'Update',
    LblLightBoxHeaderFilter: 'Select Filters',
    LblLightBoxHeaderFace: 'Select Faces',
    LblLightBoxHeaderScreen: 'Select Screens',
    LblBtnClose: 'Close',
    LblImportAgain: 'Import again',
    CheckBoxSelectAll: 'Select all',
    ColumnHeaderAddress: 'Address',
    ColumnHeaderOwnerName: 'Owner',
    ColumnHeaderName: 'Name',
    ColumnHeaderCampaignName: 'Campaign Name',
    ColumnHeaderEmail: 'Email',
    ColumnHeaderPhone: 'Phone',
    ColumnHeaderRole: 'Role',
    ColumnHeaderPassword: 'Password',
    ColumnHeaderMaxDiscount: 'Max Discount',
    ColumnHeaderUserActive: 'User active',
    ColumnHeaderOrientation: 'Orientation',
    ColumnHeaderResolution: 'Resolution',
    ColumnHeaderAvailability: 'Availability',
    ColumnHeaderFillRate: 'Fill Rate',
    ColumnHeaderFillRatePercentage: 'Fill %',
    ColumnHeaderImpression: 'Impressions',
    ColumnTooltipImpression: 'Matching filter / Total impressions',
    ColumnHeaderFlightLength: 'Ad flight',
    ColumnHeaderLastUpdated: 'Last Updated',
    ColumnHeaderSlotDuration: 'Ad duration',
    ColumnHeaderFlightType: 'Flight type',
    ColumnHeaderSaturation: 'Saturation',
    ColumnHeaderSlot: 'Slot',
    ColumnHeaderStartDate: 'Start date',
    ColumnHeaderEndDate: 'End date',
    ColumnHeaderMultiplier: 'Price change',
    ColumnHeaderPrice: 'Price',
    ColumnHeaderCPM: 'CPM',
    ColumnHeaderSuggestedPrice: 'Suggested Price',
    ColumnHeaderSelection: 'Selection',
    ColumnHeaderActions: 'Actions',
    ColumnHeaderStatus: 'Status',
    ColumnHeaderRateCardName: 'Rate Card',
    ColumnHeaderScreens: 'Screens',
    ColumnContentFillRateError: 'Not available',
    ColumnContentFillRate: '{{fill}}%',
    ColumnContentAvailabilityPartially: 'Partially',
    ColumnContentAvailabilityYes: 'Yes',
    ColumnContentAvailabilityNo: 'No',
    ColumnContentActivated: 'Yes',
    ColumnContentDeactivated: 'No',
    ColumnHeaderNumberOfGroups: 'Groups',
    ColumnHeaderNumberOfSigns: 'Signs',
    ColumnHeaderNumberOfScreens: 'Screens',
    ColumnHeaderProposalItemFlightDuration: 'Flight duration',
    ColumnHeaderDateRange: 'Start date',
    ColumnHeaderProgress: 'Progress',
    ColumnHeaderPriority: 'Priority',
    ColumnHeaderFormat: 'Format',
    GENERIC_ERROR_WITH_LINE: 'Line {{lineNumber}}:\tAn error occured.',
    LblAdvanced: 'Advanced',
    LblAllAgeGroups: 'All Age Groups',
    LblAllGenders: 'All Genders',
    LblAllOrientations: 'All orientations',
    LblAllResolutions: 'All resolutions',
    LblAudience: 'Audience',
    LblCartSummary: 'Summary',
    LblAverageSOV: 'Average SoV',
    LblSummaryImpressions: 'Impressions',
    LblItemExportDisabled: 'Selection modified. Update proposal item before exporting',
    LblCartEmpty: "Add screens to the cart by using the 'Add' button",
    LblCriteria: 'Criteria',
    LblDailyImpressions: 'Daily Impressions',
    LblDates: 'Dates',
    LblFemales: 'Females',
    LblFilterPanel: 'Filter Panel',
    LblFilterSummary: 'Filter Summary',
    LblGoals: 'Goals',
    LblItemsSelected: ' item(s) selected',
    LblKeywords: 'Keywords',
    LblFaceName: 'Face Name',
    LblScreenName: 'Screen Name',
    LblListView: 'List View',
    LblLocation: 'Location',
    LblAddress: 'Address',
    LblMales: 'Males',
    LblMapView: 'Map View',
    LblResolutionsSelected: ' resolution(s) selected',
    LblTypeOfBuy: 'Type of Buy',
    LblSlotDurationAndFlightDuration: 'Ad Duration & Flight Duration',
    LblScreenSelected: 'Screens Selected',
    LblScreens: 'Screens:',
    LblPackages: 'Packages:',
    LblScreensSelected: 'Selected Screens',
    LblScreenSelectedSingular: 'Selected Screen',
    LblPackagesSelected: ' selected packages',
    LblPackageSelectedSingular: ' selected package',
    LblSelectLocation: 'Select location(s)',
    LblSelectAddresses: 'Enter an address...',
    LblSuggestedPrice: 'Suggested Price',
    LblCPMTooltip: 'Cost per 1000 impressions',
    LblCPM: 'CPM',
    LblSubtotalPrice: 'Sub total',
    LblTotalPrice: 'Total',
    LblDiscountPrice: 'Discount',
    LblAdjustment: 'Adjustment',
    LblFinalPrice: 'Final Price',
    LblScreenCriteria: 'Associated Criteria',
    NavLogOut: 'Logout',
    ToastLogoutError: 'Error on logging you out',
    NavMenuDashboard: 'Dashboard',
    NavMenuInventory: 'Inventory',
    NavMenuInventoryBETA: 'Inventory (Beta)',
    NavMenuStaticInventory: 'Static',
    NavMenuDigitalInventory: 'Digital',
    NavMenuCreatePackages: 'Create Packages',
    NavMenuManagePackages: 'Packages',
    NavMenuManageProposals: 'Proposals',
    NavProfile: 'Profile',
    NavMenuSettings: 'Settings',
    PlaceholderCriteria: 'Select criteria...',
    PlaceholderKeywords: 'Type a keyword',
    PlaceholderEnterName: 'Enter a name filter...',
    PlaceholderProposal: 'New proposal',
    PlaceholderPackage: 'New package',
    PlaceholderPackageName: 'Package Name...',
    PlaceholderRateCard: 'Choose a rate card...',
    EmptyRateCard: '- Unassign rate card -',
    PackageUniqueName: 'New Package',
    RatecardUniqueName: 'New Rate Card',
    DynamicPricingUniqueName: 'New Dynamic Pricing',
    PlaceholderProposalName: 'Enter Proposal Name',
    PlaceholderCustomPackage: 'Line Item Name...',
    ProposalUniqueName: 'New Proposal',
    LblScreenSuffix: ' screens selected',
    LblName: 'Name: ',
    LblSavedFilters: 'Saved Filters',
    LblDynamicSelection: 'Dynamic Selection',
    LblDynamicSelectionDescription:
        '{{sign}} in this section vary based on saved filters, but will be fixed when this package is added to a proposal',
    LblFixedSelection: 'Fixed Selection',
    LblFixedSelectionDescription:
        '{{sign}} in this section will always be selected when this package is added to a proposal',
    LblDynamic: 'Enable Dynamic packaging',
    LblScreensLimit: 'Limit number of screens',
    LblParameters: 'Parameters:',
    LblDescription: 'Description:',
    LblMultiplier: 'Variable pricing:',
    LblPrice: 'Price:',
    LblYourPrice: 'Your Price:',
    LblSaturation: 'Saturation:',
    LblSlots: 'Slots',
    LblFlightDuration: 'Flight Duration:',
    LblSlotDuration: 'Ad Duration:',
    LblSynchronized: 'Synchronize screens in the package',
    LblOn: 'On',
    LblOff: 'Off',
    LblPublished: 'Published',
    LblNotPublished: 'Unpublished',
    LblBtnPublish: 'Publish',
    LblBtnUnpublish: 'Unpublish',
    LblBtnDone: 'Done',
    LblBtnBackToList: 'Back to list',
    BackToListTooltipLbl: 'Go back to Proposals list',
    LblBtnCancel: 'Cancel',
    LblBtnDelete: 'Delete',
    LblBtnSync: 'Sync',
    LblBtnSyncing: 'Syncing...',
    LblPassword: 'Password',
    LblBtnSave: 'Save',
    LblBtnDownload: 'Download PDF',
    LblBtnDownloadTooltip: 'Download pdf',
    LblBtnAmendTooltip: 'Amend',
    LblBtnBook: 'Book',
    LblBtnSubmitBook: 'Submit for booking',
    LblBtnBookNow: 'Book now',
    LblBtnRelease: 'Release',
    LblReleaseAll: 'Release all',
    LblBtnReleaseTooltip: 'Releases proposal from hold',
    LblBtnHold: 'Hold',
    LblBtnHoldTooltip: 'Check availability and hold proposal',
    LblBtnRenew: 'Renew hold',
    LblHoldRenewed: 'Hold has been renewed',
    LblHoldSet: 'Hold has been set',
    LblHoldExpiryMessage: 'Your proposal will be held until',
    LblDateTime: '{{date}} at {{time}}',
    LblHoldExpiryHint: 'Hold expires in {{expiry}}',
    LblBtnRenewTooltip: 'Renews hold on proposal',
    LblBtnReleasing: 'Releasing hold',
    LblBtnRenewing: 'Renewing hold',
    LblBtnHolding: 'Holding',
    PROPOSAL_SALESFORCE_HOLD_LOCK_ENABLED: 'Proposal cannot be held because hold lock is enabled',
    PROPOSAL_SALESFORCE_BOOK_LOCK_ENABLED: 'Proposal cannot be booked because book lock is enabled',
    ToastProposalHoldError: 'Failed to hold a proposal. Please try again later',
    ToastProposalReleaseError: 'Failed to release hold on a proposal. Please try again later',
    ToastProposalHoldRenewError: 'Failed to renew hold on a proposal. Please try again later',
    ToastProposalRequestHoldError: 'Failed to request hold on a proposal. Please try again later',
    ToastProposalRequestHoldErrorStatusNotAllowed:
        'Hold request can be submitted only for proposals with status Saved',
    ToastProposalRequestHoldErrorNotEnforced:
        'The hold request is not enforced, you do not have permission to request a hold on a proposal.',
    ToastProposalRequestHoldErrorAlreadyRequested:
        'A hold has already been requested on this proposal. Please wait until it has been either approved or rejected before trying again.',
    ToastProposalRequestHoldErrorWithoutRequest:
        'You are not allowed to hold directly and must request a hold.',
    ToastProposalRequestWrongPrivilege: 'You have the privilege to hold without request',
    ToastProposalRequestNotOwner: 'You can not request hold on proposals that you do not own',
    ToastProposalRenewRequestHoldError:
        'Failed to request renew hold on a proposal. Please try again later',
    ToastProposalRequestHoldCannotBeDeleted: 'Proposal requested for hold cannot be deleted',
    ToastProposalItemRequestHoldCannotBeDeleted:
        'Proposal items in a proposal requested for hold cannot be deleted',
    ToastProposalRequestHoldBookNotAllowed: 'You cannot book a proposal requested for hold',
    ToastProposalItemRequestHoldCloneNotAllowed:
        'You cannot clone a proposal item in a proposal requested for hold',
    ToastAddProposalItemHoldRequestNotAllowed:
        'You cannot add proposal items to a proposal requested for hold',
    ToastHoldAlreadyRequestedAndApproved:
        'A hold has already been requested and approved on this proposal. Please wait until proposal has been fully held.',
    ToastProposalReleaseSuccess: 'Inventory hold has been successfully released.',
    ToastProposalRequestHoldSuccess: 'Inventory hold has been successfully requested.',
    ToastProposalRenewRequestHoldSuccess: 'Inventory renew hold has been successfully requested.',
    LblLoadingMessagePuttingOnHold: 'Putting hold on proposal...',
    LblLoadingMessageRenewingHold: 'Renewing hold on proposal...',
    LblLoadingMessageCloneProposalItem: 'Cloning proposal item...',
    LblLoadingMessageReleaseProposalItem: 'Releasing proposal item from hold...',
    ToastLineItemCloneError: 'Failed to clone a proposal item.',
    ToastLineItemReleaseError: 'Failed to release hold on a proposal item.',
    ToastItemCancelError: 'Failed to cancel a proposal item.',
    PROPOSAL_ITEM_NOT_CANCELLABLE: 'Proposal item is not cancellable',
    LblGoBack: '&xlarr;&nbsp;Go back',
    LblBookingImpossible: 'Impossible to book',
    LblHoldImpossible: 'Impossible to hold',
    LblBookGoBackEditTooltip: 'Go back editing the proposal',
    LblBookSubmitTooltip: 'Submit for booking',
    LblHoldSubmitTooltip: 'Put hold on selected {{asset}}',
    LblBookImpossibleTooltip: 'Impossible to book when all {{asset}} are unavailable',
    LblHoldImpossibleTooltip: 'Impossible to hold when all {{asset}} are unavailable',
    LblBookRemaininingTooltip: 'Book remaining available time on the {{asset}}',
    LblHoldRemaininingTooltip: 'Hold remaining available time on the {{asset}}',
    LblAddingPackages: 'Adding packages',
    LblAddingPackage: 'Adding package',
    LblAddingSelection: 'Adding Selection',
    BookingImpossible: 'It is impossible to book a proposal without any screens available',
    AvailableTimeBooked: '(only the remaining available time will be booked)',
    UnavailableRemoved: '(these unavailable screens will be removed)',
    LblBtnBookRemaining: 'Book remaining time',
    LblBtnHoldRemaining: 'Hold remaining time',
    LblCheckingAvailability: 'Checking availability...',
    LblLoadingMessage: 'Loading...',
    LoaderSavingMessage: 'Saving changes...',
    LoadingAmendingMessage: 'Amending line item...',
    LblRetrievingProposalItem: 'Retrieving line item details...',
    LblLoadingMessagePreparingDownload: 'Preparing proposal download...',
    LblLoadingMessagePreSaving: 'Pre saving the proposal...',
    LblLoadingMessageBookingProposal: 'Booking the proposal...',
    LblLoadingMessageBookingProposalSubmitted: 'You have submitted the proposal for booking.',
    LblLoadingMessageCheckingAvailability: 'Checking availability of screens...',
    LblLoadingMessageGeneratingPDF: 'Generating PDF... This may take a while...',
    LblLoadingMessageAutoSaving: 'Auto saving proposal...',
    LblLoadingMessageSavingProposal: 'Saving the proposal...',
    LblLoadingMessageCancelProposal: 'Cancelling changes...',
    LblGeneralInfo: 'General Info',
    LblScreensNumber: 'Number of Screens',
    LblSummaryPrice: 'Proposal Price',
    LblSeeMore: 'See more',
    LblSeeLess: 'See less',
    showCriteria: 'Show criteria',
    hideCriteria: 'Hide criteria',
    AutoSaved: 'Auto-saved',
    LblSummary: 'Summary',
    LblClientInfo: 'Client Info',
    LblParticularSchedule: 'On a particular schedule',
    LblSetSpecificDays: 'Set Specific Days',
    Screens: 'Screens:',
    None: 'None',
    NotOwnerWarning: 'Your user privileges prevent you from accessing this feature.',
    PrivilegeUpdateRequest: 'To update your privileges, contact your Admin.',
    SalesforceSyncWarning: 'There was an error during the sync to Salesforce.',
    Budget: 'Budget',
    LblBudget: 'Budget',
    Impressions: 'Impressions',
    Repetitions: 'Repetitions',
    LblFlightTypeDays: 'Days',
    LblFlightTypeWeeks: 'Weeks',
    LblFlightTypeMonths: 'Months',
    DaysFlightDuration: ' days',
    WeeksFlightDuration: ' weeks',
    MonthsFlightDuration: ' months',
    FlightDurationMultiplier: 'x {{duration}} {{type}}',
    LblMatchesOf: 'matches of',
    LblScreensMatching: 'screens selected',
    LblFiltersMatching: 'screens matching filters',
    LblDescriptionPlaceholder: 'Type your description here',
    ToastFailedToAddScreens: 'Failed to add screens. Please try again.',
    ToastFailedToUpdateProposalItem: 'Failed to update proposal item.',
    ToastFailedToRemoveScreens: 'Failed to remove screens. Please try again.',
    ToastMessagePackageSaved: 'Package was successfully saved!',
    ToastMessageProposalSaved: 'Your proposal was successfully saved!',
    ToastMessageProposalSyncStarted: 'Your proposal is syncing',
    ToastMessageProposalBooked: 'Proposal was successfully booked.',
    ToastMessageCartSaved: 'Your selection has been auto-saved.',
    ToastMessageNameRequired: 'A name is required.',
    ToastScreenDetailsUnavailable: 'Failed to fetch screen details.',
    ToastCartFetchFailed: 'Failed to fetch your cart. Please try reloading the page',
    ToastProposalBookFailed: 'Failed to book a proposal. Please try again.',
    ALL_SCREENS_UNAVAILABLE:
        'This proposal does not contain available screens and cannot be booked',
    ToastNetworkError: 'Network error occurred. Please try again.',
    ToastResourceDeleteFailed: 'Unable to delete this {{resource}}',
    ToastPackageSaveError: 'Failed to save a package. Please try again',
    ToastItemExportError: 'Failed to export {{resource}}. Please try again',
    ToastResourceSaveError: 'Failed to save a {{resource}}. Please try again.',
    ToastResourcesSaveError: 'Failed to save {{resources}}. Please try again.',
    ToastAmendError: 'Failed to amend proposal item. Please try again.',
    ToastAmendSuccess: 'Proposal item has been successfully amended',
    ToastAutoSaveError: 'Failed to save your changes',
    ToastResourcesAddError: 'Failed to add {{resources}}. Please try again.',
    ToastResourcesUpdateError: 'Failed to update {{resources}}. Please try again.',
    ToastFailedScreenSelection: 'Failed to select screens',
    ToastProposalItemSaveError: 'Failed to add a proposal item. Please try again later',
    ToastProposalItemFetchError: 'Failed to fetch a proposal item. Please try again later',
    ToastProposalItemUpdateError: 'Failed to update a proposal item. Please try again later',
    ToastProposalLockedAccess:
        'This proposal is managed and editing was locked by your external systems business rules',
    ToastMessageDomainSaved: 'Domain was successfully saved!',
    ToastMessagePackageDuplicate: 'This name is already taken. Please choose another one.',
    ToastProposalAlreadyBooked: 'Proposal "{{name}}" has already been booked',
    ToastMessageProposalDuplicate: 'This name is already taken. Please choose another one.',
    ToastMessagePoiListUploadError: 'POI List file cannot be imported because of format errors.',
    PACKAGE_NAME_REQUIRED:
        'Package name is required. Provide a package name before saving the package',
    PACKAGE_NAME_DUPLICATE:
        'Package name already exists. Provide a unique package name before saving the package',
    PROPOSAL_NAME_REQUIRED:
        'Proposal name is required. Provide a proposal name before saving the proposal',
    PROPOSAL_ITEM_NAME: 'Proposal item name',
    PROPOSAL_ITEM_NAME_REQUIRED: 'Please provide a proposal item name',
    PACKAGE_NAME_TOO_LONG: 'Package name should be 512 characters max',
    PACKAGE_DESCRIPTION_TOO_LONG: 'Package description should be 4096 characters max.',
    PACKAGE_PRICE_OUT_OF_RANGE: 'Package price should be between 1 and 9999',
    PACKAGE_FLIGHT_DURATION_OUT_OF_RANGE: 'Package flight duration should be between 1 and 9999',
    PACKAGE_FLIGHT_DURATION_TYPE_OUT_OF_RANGE:
        'Package flight duration type is not valid. Please select one from the dropdown list',
    PACKAGE_SLOT_DURATION_OUT_OF_RANGE: 'Package ad duration must be between 1 and 9999',
    INVALID_PROPOSAL_ITEM_IDS: 'It is impossible to book this proposal',
    INVALID_START_DATE: 'Proposal item has to have start date on {{dow}}',
    PROPOSAL_ITEM_UNBOOKABLE: 'It is impossible to book this proposal',
    PROPOSAL_NOT_FOUND: 'Failed to find and book this proposal',
    BOOKING_FAIL: 'It is impossible to book a proposal at the moment',
    PROPOSAL_ITEM_ALREADY_BOOKED: 'This proposal is already booked',
    PROPOSAL_ALREADY_BOOKED: 'This proposal is already booked',
    PROPOSAL_NO_PREMISSION_REMOVE_HOLD: "User can't remove hold on selected proposal",
    PROPOSAL_NO_PERMISSION_HOLD: "User can't set hold on selected proposal",
    PROPOSAL_NO_PERMISSION_RENEW: "User can't renew hold on selected proposal",
    PROPOSAL_NO_PERMISSION_BOOK: "User can't book selected proposal",
    PROPOSAL_NO_PERMISSION_DELETE: "User can't delete selected proposal",
    PROPOSAL_NO_PERMISSION_CANCEL: "User can't cancel selected proposal",
    PROPOSAL_NO_PERMISSION_EDIT: "User can't edit selected proposal",
    PROPOSAL_ITEM_NOT_EDITABLE:
        'Proposal item cannot be updated because of its status or other restrictions',
    OutOfRange: 'Values must be within {{min}} and {{max}} range',
    LblCampaignFlight: 'Campaign Flight',
    LblTotalPeriod: 'Flight Dates:',
    LblFlightDates: 'Ad Flight',
    LblCampaignStatus: 'Status',
    CampaignsFetchError: 'Failed to fetch campaigns.',
    LblTime: 'Time:',
    LblDow: 'Days of the week:',
    LblMondayShort: 'M',
    LblTuesdayShort: 'T',
    LblWednesdayShort: 'W',
    LblThursdayShort: 'T',
    LblFridayShort: 'F',
    LblSaterdayShort: 'S',
    LblSundayShort: 'S',
    ErrMsgDowFormat: 'Please select at least one day',
    LblStart: 'Start:',
    LblEnd: 'End:',
    TotalDuration: '{{days}} days',
    CustomPackage: 'Manual Selection',
    ErrMsgDateFormat: 'Please use {{format}} format',
    ErrMsgTimeFormat: 'Please use {{format}} format',
    ErrMsgMaxStartTime: '23:59 cannot be used as a start time. It stops at 23:58',
    SaveAs: 'Save as',
    Package: 'Package',
    Proposal: 'Proposal',
    SelectFaces: 'Select Faces',
    SelectScreens: 'Select Screens',
    SelectScreensManual: 'Select Screens Manually',
    SelectScreensDynamic: 'Select Screens Dynamically',
    SelectFacesManual: 'Select Faces Manually',
    BuildCustomPackage: 'Build Custom Package',
    SelectAScreen: 'Select a Screen',
    SelectFilters: 'Select Filters',
    SelectPackages: 'Select Packages',
    SelectAPackage: 'Select a Package',
    LblDescriptionSelectScreensManual: 'Manual selection of specific screens',
    LblDescriptionSelectScreensDynamic: 'Automatic reservation of screens',
    LblDescriptionSelectFacesManual: 'Manual selection of specific faces',
    LblDescriptionSelectPackages: 'Choose from pre-built screen packages',
    Cancel: 'Cancel',
    Apply: 'Apply',
    LblTypePassword: 'Type Password:',
    LblConfirmPassword: 'Confirm Password:',
    ErrorPasswordTooShort: 'Password must be at least 8 characters long.',
    ErrorPasswordNotIdentical: 'Passwords must be identical.',
    SearchPlaceholder: 'What are you looking for?',
    ProposalHeader: 'Build a Proposal',
    ProposalTagline: 'Enter details of your proposal below.',
    SelectInventoryHeader: 'Select Inventory',
    SelectInventoryTagline: 'Select and configure settings',
    SelectInventoryTaglineFeatureFlagged:
        'A proposal item can be built with either a dynamic or a fixed selection of screens',
    ClientFullNameLbl: 'FULL NAME',
    ClientCompanyNameLbl: 'COMPANY NAME',
    ClientPhoneLbl: 'PHONE',
    ClientEmailLbl: 'EMAIL',
    ClientAddressLbl: 'Address',
    AdvertiserLbl: 'ADVERTISER NAME',
    ClientNamePlaceholder: "Enter client's company name...",
    ClientPhonePlaceholder: "Enter client's phone number...",
    ClientIdPlaceholder: 'Enter client ID...',
    ClientContractId: 'Enter contract ID...',
    ClientIdMessage: "Client ID won't appear on the final pdf",
    ClientContactNamePlaceholder: 'Enter contact name...',
    ClientEmailPlaceholder: 'Enter contact email...',
    ClientAddressPlaceholder: "Enter client's address...",
    AdvertiserPlaceholder: 'Enter advertiser name..',
    CreatedBy: 'Created by:',
    OwnershipLabel: 'Ownership',
    OwnershipPlaceholder: 'Start type to search...',
    PackagesSelected: 'packages selected',
    ProposalItems: 'proposal items',
    PackageLower: 'package',
    ScreenLower: 'screen',
    CountOfScreenAvailable: '{{screenCount}} screen is available for this date and time',
    'CountOfScreenAvailable-plural': '{{screenCount}} screens are available for this date and time',
    CountOfItemReportedWarnings: '{{itemCount}} item reported warnings:',
    CountOfItemsAvailable: '{{itemCount}} available for import',
    CountOfItemReportedErrors: '{{itemCount}} item reported error:',
    'CountOfItemReportedWarnings-plural': '{{itemCount}} items reported warnings:',
    'CountOfItemReportedErrors-plural': '{{itemCount}} items reported errors:',
    ErrorLineItem: 'Line {{lineItem}}:',
    INVALID_CRITERION:
        'At least one criterion does not exist in Broadsign Direct, please verify your list',
    DUPLICATE_CATEGORY_NAME:
        "Category exclusion name must be unique on each row, to add several criteria, use ';' to separate them",
    INVALID_FORMAT: 'The file you are trying to upload is not a CSV file',
    NO_CATEGORY_NAME: 'Category name cannot be empty, please verify your list',
    CATEGORY_IN_USE:
        'Cannot modify a category exclusion that has already been assigned to proposals or packages',
    CONTRACT_ID_MANDATORY:
        'Contract ID is required. Provide a contract ID before submitting the proposal',
    CONTRACT_ID_MANDATORY_SAVING:
        'Contract ID is required. Provide a contract ID before saving the proposal',
    CONTRACT_ID_UNAVAILABLE: 'Contract ID is already in use.',
    MISSING_FILE: 'Failed to upload file',
    AUDIENCE_SCHEDULE_OVERLAPS:
        'Line {{lineNumber}}:\tAudience schedule are overlapping for screen {{screenNumber}}',
    IMPRESSION_TOTAL_DOES_NOT_MATCHES:
        'Line {{lineNumber}}:\tThe addition of impression does not match with the total for screen {{screenNumber}}',
    NO_IMPRESSION_TOTAL:
        'Line {{lineNumber}}:\tThere is no impression total for screen {{screenNumber}}',
    NO_DEFAULT_AUDIENCE_SCHEDULE:
        'Line {{lineNumber}}:\tThere is no default audience schedule for screen {{screenNumber}}',
    HEADER_COUNT_MISMATCH: 'Line {{lineNumber}}:\tUnexpected number of column names in the file',
    HEADERS_MISSING: 'Line {{lineNumber}}:\tColumn names are missing',
    TOO_MUCH_DEFAULT_AUDIENCE_SCHEDULE:
        'Line {{lineNumber}}:\tThere is more than one default audience schedule for screen {{screenNumber}}',
    SCREEN_ID_DOESNT_EXIST: 'Line {{lineNumber}}:\tScreen Id: {{screenNumber}} is not found.',
    INVALID_DOW_VALUE:
        'Line {{lineNumber}}:\tInvalid day of week value for screen {{screenNumber}}',
    END_DATE_BEFORE_START_DATE:
        'Line {{lineNumber}}:\tEnd date is before Start date for screen {{screenNumber}}',
    INVALID_DATE: 'Line {{lineNumber}}:\tInvalid date format for screen {{screenNumber}}',
    INVALID_TIME: 'Line {{lineNumber}}:\tInvalid time format for screen {{screenNumber}}',
    DATE_TIME_VALUES_MISSING:
        'Line {{lineNumber}}:\tDate and/or time values missing for screen {{screenNumber}}',

    // Import/Reimport error codes
    CSV_INVALID_DATE:
        'Error in column "Start Date/End Date" at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    EXTERNAL_ID:
        'Error in column "External ID" at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    NAME: 'Error in column "Name" at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    FORMAT: 'Error in column "Format" at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    DEPLOYMENT_DATE:
        'Error in column "Deployment Date" at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    NUMBER_OF_SLOTS:
        'Error in column "Number of Slots" at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    ADDRESS: 'Error in column "Address" at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    LATITUDE:
        'Error in column "Latitude" at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    LONGITUDE:
        'Error in column "Longitude" at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    CRITERIA_LIST:
        'Error in column "Criteria List" at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    HEADING: 'Error in "Header" row at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    FILE: 'Error in "File" at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    ROW: 'Error in CVS file at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    DUPLICATE_VALUE: 'Error in CSV file at line {{lineNumber}}\t(Error message: {{errorMessage}})',
    DATES_OVERLAP:
        'Error in the "Start Date/End Date" columns at {{lineNumber}}\t(Error message: {{errorMessage}})',
    DATES_CONFLICT:
        'Error in the "Start Date/End Date" columns at {{lineNumber}}\t(Error message: {{errorMessage}})',
    MISSING_COLUMNS: 'Error in CSV file at {{lineNumber}}\t(Error message: {{errorMessage}})',
    EXTRA_COLUMNS: 'Error in CVS file at {{lineNumber}}\t(Error message: {{errorMessage}})',
    VALUES_CONFLICT: 'Error in CSV file at {{lineNumber}}\t(Error message: {{errorMessage}})',
    GENERIC_CSV_ERROR: 'Error in CSV file at {{lineNumber}}\t(Error message: {{errorMessage}})',
    NO_DATA: 'CSV file contains no data\t(Error message: {{errorMessage}})',
    PROPOSAL_NOT_IN_PLAN:
        'Proposal at line {{lineNumber}} is not included in the plan\t(Error message: {{errorMessage}})',
    FACE_ID_DOESNT_EXIST:
        'The static face at line {{lineNumber}} is not part of your domain\t(Error message: {{errorMessage}})',
    EMPTY_FILE: 'The file provided is empty',
    INVALID_VALUE: 'Line {{lineNumber}}:\tInvalid value for field "{{field}}"',
    MISSING_VALUE_PLAN: 'Line {{lineNumber}}:\tInvalid value for field "{{field}}"',
    INVALID_ENCODING:
        'Broadsign Direct does not support the encoding used. The following encodings are supported: ASCII, UTF-8, UTF-8-BOM, ISO-8859-1, ISO-8859-14',
    INVALID_IMPRESSION_TOTAL:
        'Line {{lineNumber}}:\tInvalid value for the Total Impressions Column. Only numbers and the "." characters are valid. Minimum value is 0.01',
    INVALID_IMPRESSION_TOTAL_MALES:
        'Line {{lineNumber}}:\tInvalid value for the Total Males column. Only numbers and the "." characters are valid.',
    INVALID_IMPRESSION_TOTAL_FEMALES:
        'Line {{lineNumber}}:\tInvalid value for the Total Females column. Only numbers and the "." characters are valid.',

    // Import/reimport warning messages
    FACE_WILL_BE_ADDED:
        'Face "{{faceName}}" (ID: {{faceId}}) at line {{line}} will be added from Proposal Item "{{proposalItemName}}" (ID: {{proposalItemId}})',
    FACE_WILL_BE_REMOVED:
        'Face "{{faceId}}" will be removed from Proposal Item "{{proposalItemName}}" (ID: {{proposalItemId}})',
    PROPOSAL_ITEM_WILL_BE_CANCELLED:
        'Proposal Item "{{proposalItemName}}" (ID: {{proposalItemId}}) at line {{line}} has no more associated faces and will be cancelled',
    PROPOSAL_ITEM_NOT_FOUND:
        'Proposal Item "{{proposalItemName}}" (ID: {{proposalItemId}}) associated with face {{faceName}} at line (ID: {{faceId}}) cannot be found',
    STATUS_NOT_ALLOWED:
        'Line {{lineNumber}}:\tStatus not allowed, only "Booked" & "Live" statuses can be imported',

    PlanMayBeAffectedWarning: 'Your plan may be affected by your changes',
    ImportTitleError: 'Import encountered some errors',
    ReimportStaticTitleWithWarnings: 'Validation reported some warnings',
    ReimportStaticTitleSuccess: 'Your faces will be imported',
    ImportStaticTitleSuccess: 'Static faces have been successfully imported',
    ImportDigitalTitleSuccess: 'Digital screens have been successfully imported',
    ImportTitleSuccess: 'Audience schedules have been successfully imported',
    ImportPOITitleSuccess: 'Points of interest have been successfully imported',
    LblBrowseContent: 'Browse content',
    LblUploadPicture: 'Upload Picture',
    LblDropFiles: 'Drop file here to upload or',
    LblProposalDropFiles: 'Drop a new image here to replace default',
    ErrorPictureUploadFailed: 'Failed to upload the file. Please try again.',
    NO_FILE: 'Error occured during upload: no file selected.',
    UPLOAD_FAIL: 'Failed to upload the file. Please try again.',
    UploadFailMaxSize: 'Exceeded maximum file size. Please try again.',
    UploadFailMaxNumber: 'Exceeded maximum number of files. Please try again.',
    UploadFailFileType: 'File extension is not supported. Please try again.',
    FILE_NOT_ALLOWED: 'File type is not supported. Please use image files.',
    FILE_DELETE_FAILED: 'Failed to delete the file. Please try again.',
    UPLOAD_UNKNOWN_ERROR: 'Failed to upload the file. Please try again.',
    FILE_TOO_LARGE: 'Request file is too large and cannot be uploaded. Please select another file.',
    FailedToCancel: 'Something went wrong. Please try again or try reloading the page',
    LblAvailabilityFilter: 'Availability Filter',
    LblAvailabilityFilterMessage: 'Hide unavailable screens',
    LblPrepare: 'Preparing to',
    Lbltotal: 'with a total of',
    Lblscreens: 'screens',
    LblScreenDeploymentFilterMessage: 'Hide screens not yet deployed',
    Lblare: 'are',
    Lblavailable: 'available',
    Lblpartially: 'partially available',
    LblnotAvailable: 'not available',
    LbldateTime: 'for this date and time',
    Lblshare: 'Share',
    INVALID_PROPOSAL: 'Could not find proposal',
    PROPOSAL_UPDATE_NOT_ALLOWED: 'Failed to save proposal. Please try again.',
    INVALID_PACKAGE_VALUES:
        '*<span class="st-invalid">Highlighted</span> properties do not match your proposal info. You might want to remove the associated package or adjust your proposal.',
    INVALID_PROPOSAL_STATUS: 'Proposal status is invalid',
    INVALID_FILE_FORMAT: 'Unable to save the file.',
    lblMinus18: '18-',
    lbl1824: '18 - 24',
    lbl2534: '25 - 34',
    lbl3544: '35 - 44',
    lbl4554: '45 - 54',
    lbl5564: '55 - 64',
    lbl65Plus: '65+',
    Men: 'Men',
    Women: 'Women',
    Males: 'Males',
    Females: 'Females',
    AllGenders: 'All Genders',
    NoAudience: 'No Audience',
    LblAudienceSorting: 'Audience Sorting',
    LblSelectAudience: 'Select audience',
    LblSelectDesiredAudience: 'Select desired audience: ',
    LblSelectAll: 'Select all ',
    LbLNetworkBookingChart: 'Booking level of screens',
    LbLNetworkBookingChartStatic: 'Booking level of faces',
    LblBooked: 'Booked',
    LblProposed: 'Proposed',
    LblWeek: 'Week',
    LblDay: 'Day',
    LblCampaigns: 'Campaigns',
    LblProposals: 'Proposals',
    FailedFetchBookingError:
        'Failed to fetch network booking level details. Please try reloading the page',
    DataIsUnavailable: 'The data is unavailable at the moment. Please try again later.',
    NothingBooked: 'There is nothing booked or proposed yet.',
    ImageUploadNotAllowed: "You don't have permissions to upload images",
    Draft: 'Draft',
    Saved: 'Saved',
    ExpiredHold: 'Expired Hold',
    StatusColumnHoldRequested: 'Hold Requested',
    StatusColumnRenewalRequested: 'Renewal Requested',
    Accepted: 'Accepted',
    WaitingOnTraffic: 'Waiting on traffic',
    Campaign: 'Campaign',
    Live: 'Live',
    Submitted: 'Submitted',
    Processing: 'Processing',
    OnHold: 'Held',
    PartiallyHeld: 'Partially Held',
    Cancelling: 'Cancelling',
    Cancelled: 'Cancelled',
    Booked: 'Booked',
    Ended: 'Ended',
    Approved: 'Approved',
    ToastProposalFetchFail: 'Failed to fetch proposals',
    FilterInProgress: 'In progress',
    FilterHeld: 'Held',
    FilterHoldRequested: 'Hold Request',
    FilterAllProposals: 'All proposals',
    FilterBooked: 'Booked',
    FilterLive: 'Live',
    FilterEnded: 'Ended',
    FilterSubmitted: 'Submitted',
    FilterCancelled: 'Cancelled',
    NoPackages: 'There are no packages to display.',
    NoProposals: 'There are no proposals to display.',
    NoRateCards: 'There are no rate cards to display',
    NoDynamicPricings: 'There are no variable pricing to display',
    LblSecondDiff: '{{value}} second',
    LblSecondsDiff: '{{value}} seconds',
    LblMinuteDiff: '{{value}} minute',
    LblMinutesDiff: '{{value}} minutes',
    LblHourDiff: '{{value}} hour',
    LblHoursDiff: '{{value}} hours',
    LblDayDiff: '{{value}} day',
    LblDaysDiff: '{{value}} days',
    LblMonthDiff: '{{value}} month',
    LblMonthsDiff: '{{value}} months',
    LblYearDiff: '{{value}} year',
    LblYearsDiff: '{{value}} years',
    LblEndDate: 'End date',
    LblStartDate: 'Start date',
    LblContractId: 'Contract ID',
    LblOwner: 'Owner',
    LblClientCompanyName: 'Client',
    LblSeconds: 's',
    LblPlaysPerLoop: 'Plays per loop',
    LblLoopsPerPlay: 'Loops per play',
    LblPlayPerLoop: 'Play per loop',
    LblLoopPerPlay: 'Loop per play',
    ErrorCouldNotDownloadPDF: 'Could not generate the proposal PDF file',
    ErrorSalesforceScreensEmpty: 'Reservations were not created properly for this opportunity',
    ProposalCancelTooltipLbl: 'Delete proposal permanently',
    ProposalSaveTooltipLbl: 'Save changes and close',
    ProposalSyncTooltipLbl: 'Start syncing',
    ProposalDownloadTooltipLbl: 'Save changes and download PDF',
    ProposalBookTooltipLbl: 'Check availability and submit for booking',
    LblNotAvailable: 'N/A',
    PackageScreenCountColumnTooltipTotal: 'Total screens in package',
    PackageScreenCountColumnTooltipFraction:
        'Remaining screens in package / Total screens in package',
    PackageScreenCountColumn: '{{match}} / {{total}}',
    QuickModalSaveAsTxtHeader: 'Save as Package',
    QuickModalUseFiltersTxt: 'Use Selected Filters',
    QuickModalUseSelectedAssetsTxt: 'Use Selected {{resource}}s',
    QuickModalExportAsCSV: 'Export to CSV',
    ItemImportDropdownLabel: 'Import from CSV',
    ItemExportDropdownLabel: 'Export to CSV',
    LblFullEditPackageBtn: 'Edit Package',
    LblContinueEditPackageBtn: 'Continue',
    LblDeleteConfirmBtn: 'Delete',
    LblConfirmImport: 'Confirm Import',
    ConfirmDeleteTitlePrepend: 'Preparing to delete',
    QuickModalConfirmDeleteMsg: 'Delete {{resource}} permanently?',
    SelectAllTooltipLbl: 'Click to select all matches',
    DeselectAllTooltipLbl: 'Click to deselect all matches ',
    SuggestedResolution: 'Suggested Size: 600x400',
    DefaultImage: 'Default image',
    CounterSeparatorOf: ' of ',
    Unavailable: 'Unavailable',
    TooltipUnavailable: 'Unavailable when there is no price associated with your selection.',
    TooltipPackageDone: 'Go back to package list (always saved).',
    TooltipPackageDelete: 'Delete package permanently.',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    DocAccessUrlText: 'Help',
    DocAccessUrl: 'https://broadsign.com/docs/broadsign-direct/',
    ToastUnknownError: 'Something gone wrong. Please try again',
    ToastProposalItemReserved:
        'Held proposal items are not editable. Release proposal from hold to edit',
    LblPointOfInterest: 'Point of interest',
    LblSelectPointOfInterest: 'Select POI',
    LblSelectCriteria: 'Select Criteria',
    SearchTextPlaceholder: 'Search...',
    LblPoiRadius: 'Radius',
    ToastGridFetchError: 'Unable to retrieve {{resource}}',
    ToastProposalDeleteForbidden:
        "Another user changed this proposal's status. You are now unable to delete it.",
    FirstItemCheck: 'Checking availability for the first item',
    NextItemCheck: 'Checking availability for the next item',
    LastItemCheck: 'Checking availability for the last item',
    AllBookableMessage: 'Everything can be successfully submitted for {{action}}.',
    TakeoverBookImpossible:
        'You cannot book because you have a proposal item blocked by another takeover.',
    SomeUnavailableMessage: 'Some items are unavailable for the targeted period.',
    ContinueMessage: 'If you continue...',
    LblSelectedScreens: 'Selected Screens',
    LblSelectedFaces: 'Selected Faces',
    LblSelectedSigns: 'Selected Signs',
    LblTotalSuggestedPrice: 'Total Suggested Price',
    LblYourProposal: 'Your proposal...',
    MessageWarning: 'Warning!',
    MessageProposalItemUnavailable:
        'You might want to use a different selection or booking period.',
    MessageProposalItemBlocked: 'See the blocking proposals',
    MessageProposalItemCutoffDateExceedWarning:
        'You cannot book after the cut-off date ({{cutoff_date}}).',
    MessageProposalItemCutoffDateExceedAdvice: 'You might want to use a different booking period.',
    MessageSuccess: 'Success!',
    MessageProposalItemAvailable: 'This item is available.',
    MessageProposalItemPartiallyAvailable: 'This item is available.',

    MessageAvailabilityAssetPlural: ' {{asset}}s are available',
    MessageAvailabilityAssetSingular: ' {{asset}} is available',
    MessagePartialAvailabilityAssetPlural: ' {{asset}}s are partially available',
    MessagePartialAvailabilityAssetSingular: ' {{asset}} is partially available',
    MessageNonAvailabilityAssetPlural:
        ' {{asset}}s are unavailable for this date and time and cannot be booked.',
    MessageNonAvailabilityAssetSingular:
        ' {{asset}} is unavailable for this date and time and cannot be booked.',
    MessageNotDeployedCountAssetPlural: ' {{asset}}s are not yet deployed ',
    MessageNotDeployedCountAssetSingular: ' {{asset}} is not yet deployed ',

    MessageBlockedByTakeoverAssetSingular: ' {{asset}} of this takeover item cannot be booked over',
    MessageBlockedByTakeoverAssetPlural: ' {{asset}}s of this takeover item cannot be booked over',
    MessageBlockedByTakeoverLink: 'other takeover campaigns',
    MessageBlockedByTakeoverAdvice: ' You must use a different selection or booking period.',
    MessageTakeoverBlockingAssetSingular:
        ' {{asset}} of this takeover item is also used in other proposals.',
    MessageTakeoverBlockingAssetPlural:
        ' {{asset}}s of this takeover item are also used in other proposals.',
    MessageTakeoverBlockingStart: 'Repetitions for these ',
    MessageTakeoverBlockingLink: 'held or booked proposals',
    MessageTakeoverBlockingEnd: ' will be adjusted to compensate.',

    MessageOnlyRemainingTimeWarning: 'Only the remaining time will be booked.',
    AvailabilityCheckFailed: 'Availability check for this item has failed',
    SlotDurationSecondsFormat: 's',
    LblFilterImportedList: 'Filter imported list',
    LblCustomScreenUpdateTitle: 'List of signs to use as a filter',
    LblPasteText: 'Paste text below or',
    LblCustomScreensUploadHint: 'all imported files will appear',
    LblBtnUpdateFilters: 'Update filter',
    BtnImportScreenCSV: 'Import from CSV',
    CustomScreensUploadError: 'Failed to upload custom screens.',
    CustomScreensCSVError: 'CSV file cannot be imported because of format errors',
    CustomScreenPasteError:
        'Make sure each row starts with id and values are separated with comma or whitespace',
    CustomScreenPasteInvalidScreenId:
        'CSV format error: screen id header must be specified as "screen_id"',
    LblSelectFile: 'No file selected',
    LblCustomScreensFilterChip: 'Imported list',
    LblPoiUploadText: 'Upload',
    LblCustomScreensUploadText: 'Upload',
    MsgCreatingResource: 'Creating {{resource}}. Please wait while redirecting...',
    LblFrequencyMode: 'Frequency',
    LblCPMImpressionsMode: 'Impressions Goal',
    LblCPMBudgetMode: 'Budget Goal',
    LblCPMRepetitionsMode: 'Play Goal',
    LblShareOfVoice: 'SOV',
    LblShareOfVoiceMode: 'Share of Voice',
    LblAverageSOVTooltip: 'Average Share of Voice',
    LblTakeoverMode: 'Takeover',
    LblQuickSelectButton: 'Quick Select',
    ToastScreenSelectFailed: 'Failed to update your selection. Please try again.',
    ToastCartRestoreError: 'Server error occurred, your screen selection was reset',
    LblQuickSelectInProgress: 'Updating selection',
    ProgressTooltipTarget: "Today's Target: ",
    ProgressTooltipDelivered: 'Delivered: ',
    ProgressTooltipPlanned: 'Planned: ',
    ProgressTooltipLegendImpressions: ' impr.',
    ProgressTooltipLegendRepetitions: 'rep.',
    ProgressTooltipLegendPercentage: ' %',
    ProgressTooltipCancelled: 'Cancelled',
    ProgressTooltipNotStarted: 'Not started yet',
    GoalValueNotAvailable: '--',
    GoalValueNotReachable: 'Unreachable',
    ToastProposalItemErrorUnreachable: 'Goals are unreachable. Please try a different selection.',
    UpdateGoalButtonProgress: 'Updating',
    UpdateGoalButtonLbl: 'Apply to selection',
    ToastCartError: 'Summary unavailable. Please verify your selection.',
    LblMsgCalculatingGoals: 'Calculating summary...',
    ToastGoalsHasNoAudience: 'No Audience is available for goal seek.',
    ToastGoalsHasNoScreens: 'Do not forget to select screens first.',
    Add2ndGoalButtonProgress: 'Updating',
    Add2ndGoalButtonLbl: 'Add secondary goal',
    Remove2ndGoalButtonProgress: 'Updating',
    Remove2ndGoalButtonLbl: 'Remove goal',
    LblGoalLabel: '{{value}}',
    TooltipMessageNoPrimaryGoal: 'Please, set a goal.',
    PlaceholderGoalImpressions: 'e.g. 500,000,000',
    PlaceholderGoalBudget: 'e.g. {{currency}}50,000',
    PlaceholderGoalCPM: 'e.g {{currency}}10',
    PackageBuilderTitle: 'Create a package',
    PackageBuilderSubTitle: 'Enter details of your package below.',
    PackageBuilderDynamicSelectionTitle: 'Dynamic Selection',
    PackageBuilderFixedSelectionTitle: 'Fixed Selection',
    LblMonday: 'Monday',
    LblTuesday: 'Tuesday',
    LblWednesday: 'Wednesday',
    LblThursday: 'Thursday',
    LblFriday: 'Friday',
    LblSaturday: 'Saturday',
    LblSunday: 'Sunday',
    ToastSettingsSaveSuccess: 'Settings were saved successfully',
    ToastSettingsSaveFail: 'Failed to save your settings. Please try again',
    LblLeadTimeDays: 'days',
    LblSelectFormats: 'Select Formats',
    PlaceholderFormats: 'Select formats',
    LblFormat: 'Format',
    LblImportByType: 'Select list type',
    LblImportBySignName: 'Sign name',
    LblImportBySignId: 'Sign ID',
    LblReplaceSelection: 'Replace current selection',
    LblAppendSelection: 'Append to current selection',
    MsgIdNotSpecified: 'ID not specified',
    MatchCountText: 'matches',
    LblAlsoSelectScreens: 'Also select these signs',
    LblUnavailableScreensWarning:
        'Please note that unavailable screens will not be included into selection',
    LblCart: 'Cart',
    LblProposalLineItem: 'Proposal Item',
    LblHeld: 'Held',
    LblHeldProposals: 'Held Proposals',
    DisableExternalWorkflow: 'This action is managed by an external integration workflow',
    MsgNotAuthorized:
        'The system does not recognize your credentials. Please contact your admin for more info.',
    MsgUnexpectedFailureTitle: 'Oops! Something went wrong – give it another try!',
    MsgUnexpectedFailureDescription: 'If the issue persists, get in touch at',
    SaveFeedback: {
        isSavingText: 'Saving changes...',
        lastSavedText: 'Last saved:',
    },
    NoGridData: 'No data is available',
    RequiredValue: '{{value}} is required',
    MinValueError: '{{value}} has to be greater than {{min}}',
    MaxValueError: '{{value}} has to be smaller than {{ max}}',
    BtnChoose: 'Click to choose',
    BtnChange: 'Click to change',
    NotApplicableLabel: 'N/A',
    LblDuration: 'Duration',
    DeleteHeader: 'Preparing to delete',
    CancelHeader: 'Preparing to cancel',
    CancelMessage: 'You are about to cancel this proposal item. Are you sure you want to proceed?',
    No: 'No',
    Yes: 'Yes',
    LoadingProposalItems: 'Fetching proposal items',
    UpdatingProposalItems: 'Updating proposal items',
    UpdatingProposalItem: 'Updating proposal item',
    ToastErrorProposalItemFetch: 'Failed to fetch proposal items',
    FillPressureNote: 'Maximum display period is six months',
    FillPressureError: 'Oops! Something went wrong',
    LblCategoryExclusions: 'Category Exclusions',
    LblCategoryExclusionsDescription:
        'The categories selected will apply to all selected screens and can not be updated once selections have been made.',
    LblSelectExclusions: 'Select category exclusions',
    LblProposalCategoryExclusionDescription:
        'Exclusions apply to all screens, they cannot be updated once selections have been made',
    LblPreemtibleCampaign: 'Pre-emp. campaign',
    PreemptibleTypeOfBuyDisabled: 'To edit Type of Buy please disable preemptible campaign',
    LblTotalRepetitions: 'Total Plays',
    GroupCountPlural: '{{groupCount}} Groups',
    GroupCountSingular: '{{groupCount}} Group',
};
